<template>
  <div class="row">
    <div class="col-xl-3">
      <h1>Formularz kontroli laboratorium</h1>

      <b-form-group label="Przedmiot / sprzęt">
        <b-form-input v-model="control.item" />
      </b-form-group>

      <b-form-group label="Data kontroli">
        <b-form-datepicker v-model="control.service_date" />
      </b-form-group>

      <b-button @click="storeData" variant="primary" class="mt-3"
        >Zapisz</b-button
      >
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LabControlForm",
  data() {
    return {
      control: {
        item: "",
        service_date: "",
        date: "",
        deleted: 0,
      },
    };
  },
  methods: {
    storeData() {
      let payload = {
        ...this.control
      };

      if (this.$route.params.id)
        this.$store.dispatch("labControlEdit", {
          id: this.$route.params.id,
          ...payload,
        });
      else this.$store.dispatch("labControlAdd", payload);

      this.$store.dispatch("labControlList");

      this.$router.push("/lab/control");
    },
  },
  watch:   {
    labControl: {
      handler() {
        let items = this.labControl.filter(
            (el) => el.id == this.$route.params.id
        );

        if (items.length > 0)
          this.control = {
            ...items[0],
            service_date: items[0].service_date.date,
            date: items[0].date.date
        };
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters(["labControl"])
  },
  mounted() {
    this.$store.dispatch("labControlList");

    this.control.date = new Date().getTime() / 1000;
    this.control.service_date = new Date();
  },
};
</script>

<style scoped></style>
